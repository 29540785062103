import gql from 'graphql-tag';
import React, { createRef, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Divider, Dropdown, Header, Icon, List, Placeholder, Popup, Segment, Sticky } from "semantic-ui-react";
import GeoMap from './GeoMap';
import Hideable from './Hideable';
import InlineContent from './InlineContent';
import TimeUtil from './TimeUtil';
import Util from './Util';
import moment from 'moment';
import Dataset from './Dataset';
import ContentType from './ContentType';
import AdContent from './AdContent';
import RefreshButton from './RefreshButton';
import Lead from './Lead';
import { useQuery } from '@apollo/client';

const TRAVEL_BY_ID = gql`
  query TravelById($travelId: String!, $first: Int, $limit: Int) {
      observation(criteria: {subjectId: $travelId, first: $first, limit: $limit}) {
          countReturned
          countAvailable
          observations {
              id
              modified
              title
              category
              type
              start
              end
              contentType
              content
          } 
      }
      travelInformation(travel: $travelId) {
        id
        created
        label
        departure
        arrival
        status
      }
  }`
  ;

const PAGE_SIZE = 30;

const TravelDetails = props => {
    const params = useParams();

    const {loading, error, data} = useQuery(
        TRAVEL_BY_ID, 
        {client: props.client, 
            variables: {name: props.name, travelId: params.id, first: 1, limit: PAGE_SIZE},
            skip: !params.id && !props.name
        }) 

    if (loading) { return <Placeholder fluid>
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
            <Placeholder.Line length='full' />
        </Placeholder> 
    }

    if (error) {
        return "Error: " + error
    }

    if (!data || (!data.observation && !data.observation)) {
        return <span>You are not subscribed to any travel.</span>;
    }
    
    return (<TravelContent 
        className='h100'
        data={data}
        client={props.client}
        />)
}

const parseObservations = obs => {
    obs = obs || {countAvailable: 1, observations: []};

    obs = ({
        ...obs,
        observations: obs.observations.map(o => ({
            ...o,
            content: (typeof(o?.content) === 'string' && o?.contentType?.endsWith("+json")) ? JSON.parse(o.content) : o.content
        }))
    })

    return obs;
}

const TravelContent = props => {
    const data = props.data;
    const obs = parseObservations(data.observation)
    const contextRef = createRef();
    const MAX_SCAN_TIME_MINUTES = 5;
    const travelAgeMinutes = ageMinutes(data.travelInformation.created);
    const routing = travelAgeMinutes < MAX_SCAN_TIME_MINUTES && obs?.observations?.length === 0;
    const scanning = travelAgeMinutes < MAX_SCAN_TIME_MINUTES && obs?.observations?.length > 0 && obs?.observations?.filter(o => o?.category !== 'Route')?.length === 0;
    const [selection, setSelection] = useState(getRenderSelections(data?.travelInformation, obs.observations)[0]);
    const [selectionTime, setSelectionTime] = useState(new Date());
    const navigate = useNavigate();

    const setSelection2 = s => {
        setSelection(s);
        setSelectionTime(new Date());
    }
    
    document.title = `navscout - ${data?.travelInformation?.label}`;

    return <div ref={contextRef}  className='h100'>
        <Breadcrumb>
            <Breadcrumb.Section link><Link to='/drive'>Drive</Link></Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link><Link to={`/drive/${data?.travelInformation?.id}`}>{data?.travelInformation?.label || 'My Trip'}</Link></Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>Details</Breadcrumb.Section>
        </Breadcrumb>
        <Divider hidden />
        <h1>
            {data?.travelInformation?.label || props.name || 'Your Trip'}
        </h1>
        <Hideable hidden={!data?.travelInformation?.departure}>
            <h3>{TimeUtil.range(data?.travelInformation?.departure,data?.travelInformation?.arrival)}</h3>
        </Hideable>
        <Hideable hidden={!data?.travelInformation?.id}>
        <Button icon='edit' onClick={() => navigate(`/drive/edit/${data.travelInformation.id}`)} />
        <RefreshButton client={props.client} queries={['TravelById']} />
        <Divider horizontal />
        </Hideable>
        <TravelMap 
            client={props.client}
            observations={obs.observations}
            travel={data?.travelInformation}
            departure={data?.travelInformation?.departure}
            selection={selection}
            selectionTime={selectionTime}
            contextRef={contextRef}
            />
        <br /><br/>
        <AdContent id='ad' />
        <Hideable hidden={!routing && !scanning}>
            <Segment placeholder>
                <Header icon>
                <Icon name='cog' />
                This trip is being {routing ? 'routed' : 'scanned'}.  Refresh this page to view updated information.
                </Header>
            </Segment>
        </Hideable>
        <TravelInfo
            type='WARNING'
            travel={data?.travelInformation}
            observations={obs.observations}
            client={props.client}
            setSelection={setSelection2}
            />
        <br/>
        <TravelInfo
            type='INFORMATION'
            travel={data?.travelInformation}
            observations={obs.observations}
            client={props.client}
            setSelection={setSelection2}
            />
        <br/>
    </div>
}

const ageMinutes = t => moment().diff(moment(t), 'minutes')

//Determine if observation content can be shown in the map
const mapRenderableObservation = (travel, observation) => {
    if (observation.category === 'Route' && observation.title !== 'Speed') {
        return false;
    }

    //Always show warnings
    if (observation.type === 'WARNING') {
        return true;
    }

    //Always show datasets
    if (observation.contentType === 'application/vnd.dataset+json') {
        return true;
    }

    //Show text if it is a part of the trip only
    if (observation.contentType === 'text/plain') {
        if (observation.start === null || observation.end === null) {
            return false
        }

        if (observation.start === travel.departure && observation.end === travel.arrival) {
            return false
        }
    }
    return false
}

const mapRenderableColumnCount = observation => {
    if (observation.contentType !== 'application/vnd.dataset+json') {
        return 1;
    }

    const dataset = observation.content;

    var columnCount = dataset.units.length;

    if (dataset.units[0] === 'timestamp') {
        columnCount = columnCount - 1;
    }

    return columnCount;
}

const TravelInfo = props => {   
    if (!props.observations || props.observations.length === 0) {
        return null;
    }

    var infoByCategory = new Map();

    props.observations.filter(i => i.type === props.type && !(i.category === 'Route' && i.title === 'Path')).forEach(i => {
        var info = infoByCategory.get(i.category);
        if (!info) {
            info = [];
            infoByCategory.set(i.category, info)
        }
        info.push(i);
    });

    return <div>
        { props.type === 'WARNING' ? <h2>Alerts</h2> : <h2>Reports</h2> }
        {Array.from(infoByCategory, ([k,v]) => {
            return <div key={k}>
                <h3>{Util.capitalize(k)}</h3>
                {v.sort((a, b) => a.title.localeCompare(b.title)).map(o => 
                    <Lead 
                        key={o.id}
                        title={o.title}
                        content={(
                            <span>
                                <InlineContent observation={o} />
                                {(
                                    mapRenderableObservation(props.travel, o) 
                                        ? <PlotObservationButton observation={o} setSelection={props.setSelection} />
                                        : null
                                )}
                            </span>
                        )}
                        uri={`/report/${o.id}`}
                        />
                )}
            </div>
        })}
    </div>
}

const PlotObservationButton = props => {
    const o = props.observation;

    const labels = [];
    
    o?.content?.units?.forEach((u, i) => {
        if (i > 0 || u !== 'timestamp') {
            labels.push(o.content.labels[i]);
        }
    })

    const count = mapRenderableColumnCount(o);

    return (
        <Popup
            flowing
            hoverable
            trigger={<span className='link' onClick={e => {
                    if (count === 1) {
                        props.setSelection({title: o.title, col: labels ? labels[0] : null})
                    }
                }}>
                        <Icon name='map' /> 
                        { count > 1? `(${count})` : null }
                    </span>}
            >
            <List>
                <b>Show in map</b>
                {labels.map(l => 
                    <List.Item key={l} className='link' onClick={e => props.setSelection({title: o.title, col: l})}>{l}</List.Item>
                )}
            </List>
        </Popup>
    )
}

const observationByTitle = (l, title) => l?.filter(o => o.title === title)[0];

const parseValues = ds => {
    for (let col = 0; col < ds.data.length; col++) {
        const unit = ds.units[col];
        if (unit === 'timestamp') {
            for (let row = 0; row < ds.data[col].length; row ++) {
                if (typeof ds.data[col][row] === 'string') {
                    ds.data[col][row] = new Date(ds.data[col][row]);
                }
            }
        }
    }
}

const getRenderSelections = (travel, observations) => {
    var selections = [];

    observations.forEach(o => {
        if (mapRenderableObservation(travel, o)) {
            if (o.contentType === ContentType.DATASET) {
                for (let col = 0; col < o.content.data.length; col ++) {
                    if (o.content.units[col] !== 'timestamp') {
                        selections.push({title: o.title, col: o.content.labels[col], unit: o.content.units[col]});
                    }
                }
            } else if (o.contentType === ContentType.TEXT) {
                selections.push({title: o.title});
            }
        }
    });

    selections.sort((a, b) => a.title > b.title ? 1 : a.title < b.title ? -1 : a.col > b.col ? 1 : a.col < b.col ? -1 : 0)

    return selections;
}

const TravelMap = props => {
    const path = props.observations.filter(o => o.category === 'Route' && o.title === 'Path' && o.contentType === ContentType.DATASET)[0];

    const pathContent = path?.content
    const pathData = pathContent?.data;

    const renderSelections = getRenderSelections(props?.travel, props?.observations);
    const renderSelection = useRef(props.selection);
    const [index, setIndex] = useState(0);
    const [indexTime, setIndexTime] = useState(new Date());

    const datasetRef = useRef(null);

    const mapData = useRef({});

    // Route has not yet been determined
    if (!path) {
        return "routing...";
    }

    const updateRenderSelection = selection => {
        //Update renderSelection, index if selection changed
        if (renderSelection.current !== selection) {
            renderSelection.current = selection;

            const selectedTitle = renderSelection.current.title;
            const selectedCol = renderSelection.current.col;
            const selectedColUndefined = (typeof selectedCol === 'undefined');

            const indexedSelection = renderSelections.map(
                        (s, i) => [s,i]).filter(
                            rs => rs[0].title === selectedTitle &&
                                    (selectedColUndefined || rs[0].col === selectedCol)
                        )[0];

            setIndex(indexedSelection[1]);
        }

        const dataset = observationByTitle(props?.observations, selection?.title);
        datasetRef.current = dataset;

        const renderCol = dataset?.content?.labels?.map((l, i) => l === renderSelection.current.col ? i : null)?.filter(i => i !== null)[0] || 0;

        mapData.current = {route:[]};
        
        const isDataset = dataset?.contentType === ContentType.DATASET;
        const isDisplayType = !isDataset && ContentType.isDisplayType(dataset?.contentType);

        //generate map data
        if (dataset && dataset.content) {
            if (isDataset) {
                parseValues(dataset.content);
                const data = dataset.content.data;

                if (Dataset.isNumeric(data[renderCol][0])) {
                    const minValue = data[renderCol].reduce((a, b) => Math.min(a, b));
                    const maxValue = data[renderCol].reduce((a, b) => Math.max(a, b));
                    const unit = dataset.content.units[renderCol];
                    
                    mapData.current.legend = {type: 'gradient', min: minValue, max: maxValue, unit: unit};
                }
            }
        }                

        let inTextRegion = false;

        //Add route values
        for (var i = 0; i < pathData[0].length; i++) {
            const latitude = parseFloat(pathData[2][i]);
            const longitude = parseFloat(pathData[3][i]);
            const departure = props.departure ? moment(props.departure) : null;
            
            const seconds = parseFloat(pathData[0][i]);
            const time = departure ? moment(departure).add(seconds, 'seconds') : null;

            if (dataset && dataset.content) {
                if (isDataset) {          
                    const timeCol = dataset.content?.units?.map((u, i) => u === 'timestamp' ? i : null)?.filter(i => i !== null)[0];
                    
                    const value = ((typeof timeCol === 'undefined')
                            ?  dataset.content.data[renderCol][0]
                            : Dataset.lookupWithInterpolate(dataset.content.data, timeCol, time.toDate(), renderCol)
                    );
                    
                    mapData.current.route.push({latitude, longitude, value});
                } else if (isDisplayType) {
                    if (time.isBetween(dataset.start, dataset.end, undefined, '[)')) {
                        if (!inTextRegion && mapData.current.route.length > 0) {
                            
                            mapData.current.route[mapData.current.route.length - 1].value = 'Present';
                        }
                        mapData.current.route.push({latitude, longitude, value: 'Present'});
                        inTextRegion = true;
                    } else {
                        if (inTextRegion) {
                            mapData.current.route.push({latitude, longitude, value: 'Present'});
                        } else {
                            mapData.current.route.push({latitude, longitude, value: 'Not Present'});
                        }
                        
                        inTextRegion = false;
                    }
                }
            } else {
                mapData.current.route.push({latitude, longitude});
            }
        }
    }      
    
    if (renderSelection.current !== props.selection) {
        if (props.selectionTime.getTime() > indexTime.getTime()) {
            updateRenderSelection(props.selection);
        } else {
            //Index selection is newer and should be used
        }
    }

    updateRenderSelection(renderSelection.current);

    return (
        <div style={{height:400, display: 'block'}}>
            <GeoMap 
                client={props.client}
                poll={() => renderSelection.current}
                loadData={(id, label, bbox, cb) => {
                    if (!renderSelection.current) {
                        console.log("Render map without selection")
                        cb([{
                            id: 'default',
                            geometry: {
                                type: "LineString",
                                coordinates: mapData.current.route?.map(c => [c.longitude, c.latitude]) || []
                            }
                        }])
                    } else {
                        console.log("Render map with selection")
                        cb([{
                            id: renderSelection.current.title + renderSelection.current.col,
                            geometry: {
                                type: "LineString",
                                coordinates: mapData.current.route.map(c => [c.longitude, c.latitude])
                            },
                            values: mapData.current.route.map(s => s.value),
                            legend: mapData.current.legend
                        }])
                    }
                }}
                />
                <Dropdown 
                options={(
                    renderSelections
                        .map((s,i) => {return {key: s.title + s.col, value: i, text: s.title +  (s.col ?' / ' + s.col : '')}})
                )}
                value={index}
                fluid
                search
                selection
                onChange={(e,d) => {
                        setIndexTime(new Date());
                        setIndex(d.value);
                        updateRenderSelection(renderSelections[d.value]);
                    }
                }
                />
            </div>
    )
}

export default TravelDetails;