import { ApolloClient, InMemoryCache, createHttpLink, gql, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { useEffect, useState } from 'react';
import { NavLink, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { Button, Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import About from './About';
import Account from './Account';
import './App.css';
import Chat from './Chat';
import ErrorBoundary from './ErrorBoundary';
import Hideable from './Hideable';
import Home from "./Home";
import Images from './Images';
import SignIn from './SignIn';
import SignInGetToken from "./SignInGetToken";
import SignInToken from "./SignInToken";
import Place from "./Place";
import PlaceWeather from './PlaceWeather';
import Places from './Places';
import Purchase from './Purchase';
import Register from './Register';
import Report from "./Report";
import ReportEdit from './ReportEdit';
import Reports from './Reports';
import Resources from './Resources';
import Travel from "./Travel";
import TravelDetails from "./TravelDetails";
import TravelEdit from './TravelEdit';
import Travels from './Travels';
import NotificationDisplay from './NotificationDisplay';
import UploadMedia from './UploadMedia';
import AboutPrivacy from './AboutPrivacy';
import AboutTermsOfService from './AboutTermsOfService';
import AccountPicture from './AccountPicture';
import AppInfo from './AppInfo';

const ACCOUNT = gql`
query Account {
  account {
    id
    name
    purchase {
      status
    }
    roles
    pictureUrl
  }
}`;

const LOGOUT = gql`
mutation Logout {
  logout
}`;

const httpLink = createHttpLink({uri: process.env.REACT_APP_API_URI, withCredentials: true})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : "",
      'x-api-key': process.env.REACT_APP_API_KEY
    }
  }
});


const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URI,
  // link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: 'include',
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY
  }
});

const App = props => {
  const [searchParams] = useSearchParams();
  const { loading, data } = useQuery(ACCOUNT, { client: client });
  const account = data?.account;
  const [notifications, setNotifications] = useState([])

  const onError = e => setNotifications([...[e], ...notifications])

  console.log(`App version: ${process.env.REACT_APP_VERSION}`)

  AppInfo.setProfile(searchParams.get('app.profile'))

  if (loading) {
    return null
  }

  return <ErrorBoundary>
    <div className='presentation'>
      <NotificationDisplay
        notifications={notifications}
        onClose={() => setNotifications([])}
        />
      <TopMenu account={account} client={client} />
      <div style={{ padding: '1em' }} className='main' id='main'>
        <Routes>
          <Route path='/about' element={<About client={client} />} />
          <Route path='/about/privacy' element={<AboutPrivacy client={client} />} />
          <Route path='/about/terms-of-service' element={<AboutTermsOfService client={client} />} />
          <Route path='/account' element={<Account client={client} />} />
          <Route path='/account/purchase' element={<Purchase client={client} />} />
          <Route path='/chat' element={<Chat client={client} account={account} />} />
          <Route path='/place' element={<Places account={account} client={client} onError={onError} />} />
          <Route path='/place/:id' element={<Place client={client} />} />
          {/* <Route path='/place/:subjectId/chat' element={<Chat subjectType='PLACE' client={client} account={account} />} /> */}
          <Route path='/place/:id/weather' element={<PlaceWeather account={account} client={client} />} />
          <Route path='/report' element={<Reports account={account} client={client} />} />
          <Route path='/report/add' element={<ReportEdit client={client} />} />
          <Route path='/report/edit/:id' element={<ReportEdit client={client} />} />
          <Route path='/report/:id' element={<Report account={account} client={client} />} />
          {/* <Route path='/report/:subjectId/chat' element={<Chat subjectType='RECORD' client={client} account={account} />} /> */}
          <Route path='/drive' element={<Travels client={client} />} />
          <Route path='/drive/new' element={<TravelEdit account={account} client={client} />} />
          <Route path='/drive/edit/:id' element={<TravelEdit account={account} client={client} />} />
          <Route path='/drive/:id' element={<Travel client={client} />} />
          <Route path='/drive/:id/details' element={<TravelDetails client={client} />} />
          {/* <Route path='/drive/:subjectId/chat' element={<Chat subjectType='DRIVE' client={client}  account={account}  />} /> */}
          <Route path='/gallery' element={<Images account={account} client={client} />} />
          <Route path='/gallery/upload' element={<UploadMedia account={account} client={client} />} />
          <Route path='/resources' element={<Resources client={client} />} />
          <Route path='/sign-in' element={<SignIn client={client} account={account} />} />
          <Route path='/sign-in/get-token' element={<SignInGetToken client={client} />} />
          <Route path='/sign-in/token' element={<SignInToken client={client} />} />
          <Route path='/register' element={<Register account={account} client={client} />} />
          <Route path='/' element={<Home account={account} client={client} />} />
        </Routes>
      </div>
    </div>
  </ErrorBoundary>
}


const TopMenu = props => {
  const [mobile, setMobile] = useState(window.innerWidth < 720)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  const navigate = useNavigate();

  return <Menu style={{height: '3em', minHeight: '3em'}} secondary className='header'>
    <Menu.Item
      name={mobile ? '' : 'home'}
      icon='home'
      as={NavLink}
      to='/'
    />
    <Menu.Item
      name={mobile ? '' : 'drive'}
      icon='car'
      as={NavLink}
      to='/drive'
    />
    <Menu.Item
      name={mobile ? '' : 'places'}
      icon='map marker alternate'
      as={NavLink}
      to='/place'
    />
    <Menu.Item
      name={mobile ? '' : 'chat'}
      icon='chat'
      as={NavLink}
      to='/chat'
    />
    <Menu.Menu position='right' style={{margin: '0.5em'}}>
      <Dropdown floating icon='grid layout' style={{padding:'0.4em'}} direction='left'>
        <Dropdown.Menu>
          <Dropdown.Item as={NavLink} to='/gallery' icon='images outline' text='Gallery'></Dropdown.Item>
          <Dropdown.Item as={NavLink} to='/report' icon='file alternate outline' text='Reports'></Dropdown.Item>
          <Dropdown.Item as={NavLink} to='/about' icon='info' text='About'></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Hideable hidden={!props.account}>
        <Dropdown floating trigger={ <AccountPicture account={props.account} /> } >
          <Dropdown.Menu>
              <Dropdown.Item onClick={() => navigate('/account')}>Account</Dropdown.Item>
              <Dropdown.Item text='Logout' onClick={e => {
                e.preventDefault();
                logout(props.client, navigate);
              }} />
          </Dropdown.Menu>
        </Dropdown>
      </Hideable>
      <Hideable hidden={props.account}>
        <Button color='blue' onClick={() => navigate('/sign-in')}>Sign In</Button>
      </Hideable>
    </Menu.Menu>
  </Menu>
}

const logout = (client, navigate) => {
  client.mutate({
    mutation: LOGOUT,
    refetchQueries: ['Account', 'Alerts', 'FindPlace', 'GetCalendar', 'Places']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Sign out failed');
    } else {
      console.log('Signed out');
      navigate('/sign-in');
    }
  });
}

export default App;
