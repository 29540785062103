import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Confirm, Container, Divider, Form } from 'semantic-ui-react';
import AccountPasswordUpdate from './AccountPasswordUpdate';
import Hideable from './Hideable';
import SignInRequired from './SignInRequired';
import Util from './Util';
import PlatformRestricted from './PlatformRestricted';
import Payment from './Payment';

const ACCOUNT_INFO = gql`
query AccountInfo {
  account {
    id
    name
    familyName
    givenName
    email
    purchase {
      label
      status
    }
  }
}
`
;

const UPDATE_ACCOUNT = gql`
mutation UpdateAccount($name:String, $familyName:String, $givenName:String, $email:String!) {
  updateAccount(input: {name:$name,familyName:$familyName,givenName:$givenName,email:$email}) {
    created
  }
}`;

const UPDATE_PASSWORD = gql`
mutation UpdatePasssword($password:String!) {
  updatePassword(password:$password)
}
`;

const CANCEL_SUBSCRIPTION = gql`mutation CancelSubscription { cancelSubscription { label } }`;

const DELETE_ACCOUNT = gql`mutation DeleteAccount { deleteAccount }`;

const Account = props => {
  const [values, setValues] = useState({name: '', familyName: '', givenName: '', email: ''});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);
  const navigate = useNavigate();
  const {loading, error, data} = useQuery(ACCOUNT_INFO, {
    client: props.client,
    onCompleted: data => setValues(data.account)
  });  

  if (!Util.hasSession()) {
    return <SignInRequired />
  }

  if (loading) {
    return 'Loading...';
  }

  if (error) {
    return `Error loading account:  + ${error}`
  }

  const handleChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const activeAccount = data?.account?.purchase && data?.account?.purchase?.status !== 'INACTIVE';

  document.title = 'navscout - Account';

  return <div>
    <h1>Account</h1>
      <Form fluid="true" onSubmit={e => {
          e.preventDefault();
          updateAccount(props.client, values);
        }} >
        <Form.Field>
          <label>Email</label>
          <input name='email' placeholder='Email' value={values.email} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Name</label>
          <input name='name' placeholder='Name' value={values.name} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Given Name</label>
          <input name='givenName' placeholder='Given Name' value={values.givenName} onChange={handleChange} />
        </Form.Field>
        <Form.Field>
          <label>Family Name</label>
          <input name='familyName' placeholder='Family Name' value={values.familyName} onChange={handleChange} />
        </Form.Field>
        <Container textAlign='center'>
          <Button 
              style={{margin:'0 auto 0 auto'}}
              primary={true}
              >Update</Button>
        </Container>
      </Form>
      <Divider hidden style={{height:'2em'}} />
      <Container textAlign='center'>
        <AccountPasswordUpdate
          onSubmit={values => updatePassword(props.client, values) }
          />
      </Container>
      <Divider hidden style={{height:'2em'}} />
      <Container textAlign='center'>
        <h3>Your Plan</h3>
        <Hideable hidden={activeAccount}>
          <b>You do not have an active account.</b>
          <Payment>
            <Link to="/account/purchase">Purchase a Plan</Link><br/>
          </Payment>
        </Hideable>
        <Hideable hidden={!data.account?.purchase}>
          {data.account?.purchase?.label}<br/>
          Status: {data.account?.purchase?.status}<br/>
          <Button 
                style={{margin:'0 auto 0 auto'}}
                primary={true}
                onClick={() => setConfirmCancelOpen(true)}
                disabled={data.account?.purchase?.status === 'INACTIVE'}
                >Cancel</Button>
          <Confirm 
                open={confirmCancelOpen}
                onCancel={() => setConfirmCancelOpen(false)}
                onConfirm={() => {
                  setConfirmCancelOpen(false);
                  cancelSubscription(props.client);
                }}
                />
        </Hideable>
      </Container>
      <Divider hidden style={{height:'2em'}} />
      <Container textAlign="center">
        <Button 
              style={{margin:'0 auto 0 auto'}}
              primary={true}
              onClick={() => setConfirmDeleteOpen(true)}
              >Delete Account</Button>
        <Confirm 
          open={confirmDeleteOpen}
          onCancel={() => setConfirmDeleteOpen(false)}
          onConfirm={() => deleteAccount(props.client, navigate)}
          />
        </Container>
    </div>;
}

const updateAccount = (client, values) => {
  console.log('Update account ', values);
  client.mutate({
    mutation: UPDATE_ACCOUNT,
    variables: values,
    refetchQueries: ['AccountInfo']
  }).then(data => {
    if (data.data.register === null) {
      console.log('Update account failed');
    }
  });
}

const updatePassword = (client, values) => {
  console.log('Update password ');
  if (!values.password) {
    return;
  }
  client.mutate({
    mutation: UPDATE_PASSWORD,
    variables: values,
    refetchQueries: ['AccountInfo']
  }).then(data => {
    if (data.data.updatePassword === null) {
      console.log('Update password failed');
    }
  });
}

const cancelSubscription = (client) => {
  console.log('Cancel subscription');
  client.mutate({
    mutation: CANCEL_SUBSCRIPTION,
    refetchQueries: ['Account', 'AccountInfo']
  }).then(data => {
    if (!data.data.cancelSubscription) {
      console.log('Cancel subscription failed');
    } else {
      console.log('Canceled subscription');
    }
  });
}

const deleteAccount = (client, navigate) => {
  console.log('Delete account');
  client.mutate({
    mutation: DELETE_ACCOUNT,
    refetchQueries: ['Account', 'AccountInfo']
  }).then(data => {
    if (!data.data.deleteAccount) {
      console.log('Delete account failed');
    } else {
      navigate('/');
    }
  });
}

export default Account;