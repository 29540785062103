import React, { useEffect, useState } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Accordion, AccordionContent, AccordionTitle, Container, Header, Icon, Image, List, TableCell, TableRow, Table, TableBody } from 'semantic-ui-react';
import './App.css';
import { Link } from 'react-router-dom';
import AppInfo from './AppInfo';

const getGooglePlayService = async () => {
    if (!'getDigitalGoodsService' in window) {
        return false
    }
    try {
        const service = await window.getDigitalGoodsService('https://play.google.com/billing')
        return true
    } catch (error) {
        return false
    }
}

const yesNo = v => v ? 'Yes' : 'No'

const About = props => {
    const [showDebug, setShowDebug] = useState(false)
    const [googlePlaySupported, setGooglePlaySupported] = useState('?')
    const [relatedApps, setRelatedApps] = useState([])

    // getGooglePlayService().then(r => setGooglePlaySupported(r ? 'yes' : 'no'))
    useEffect(() => {
        AppInfo.getRelatedApps().then(setRelatedApps)
    })
    
    return (
        <Container fluid>
            <Image src='/image/navscout-logo-512.png' />
            {/* &nbsp; */}
            {/* <a href='javascript:window.location.reload()'>Update</a> */}
            <h1>Information</h1>
            <Link to='/about/privacy'>Privacy Policy</Link>
            <br/>
            <Link to='/about/terms-of-service'>Terms of Service</Link>
            <br/>
            <Accordion>
                <AccordionTitle index={0} active={showDebug} onClick={() => setShowDebug(!showDebug)}>
                    <Icon name='dropdown' />
                    App Info
                </AccordionTitle>
                <AccordionContent active={showDebug}>
                    <Table definition>
                        <TableBody>
                        <TableRow><TableCell>Version</TableCell><TableCell>{process.env.REACT_APP_VERSION || 'Unknown'}</TableCell></TableRow>
                            <TableRow><TableCell>Platform</TableCell><TableCell>{AppInfo.getPlatform()}</TableCell></TableRow>
                            <TableRow><TableCell>Profile</TableCell><TableCell>
                                {AppInfo.getProfile()}
                                &nbsp;
                                <Icon name='refresh' link onClick={() => {AppInfo.setProfile(null, true)}} />
                            </TableCell></TableRow>
                            <TableRow><TableCell>Display Mode</TableCell><TableCell>{AppInfo.getDisplayMode()}</TableCell></TableRow>
                            <TableRow><TableCell>User Agent</TableCell><TableCell>{AppInfo.getUserAgent()}</TableCell></TableRow>
                            {/* <TableRow><TableCell>Google Play Supported?</TableCell><TableCell>{googlePlaySupported}</TableCell></TableRow> */}
                            <TableRow><TableCell>Mobile</TableCell><TableCell>{yesNo(AppInfo.isMobile())}</TableCell></TableRow>
                            <TableRow><TableCell>Brands</TableCell><TableCell>{AppInfo.getUserAgentData()?.brands?.map(b => b.brand)?.reduce((a,b) => {return a === "" ? b : a + ", " + b}, "") || "None"}</TableCell></TableRow>
                            <TableRow><TableCell>Related Apps</TableCell><TableCell>{relatedApps?.reduce((a,b) => {return a === "" ? b : a + ", " + b}, "") || "None"}</TableCell></TableRow>
                            <TableRow><TableCell>Allow Payment</TableCell><TableCell>{yesNo(AppInfo.allowPaymentManagement())}</TableCell></TableRow>
                            <TableRow><TableCell>Android App</TableCell><TableCell>{yesNo(AppInfo.isAndroidApp())}</TableCell></TableRow>
                            {/* <TableRow><TableCell></TableCell><TableCell></TableCell></TableRow> */}
                        </TableBody>
                    </Table>
                </AccordionContent>
            </Accordion>
            {showDebug}
            <h1>Social</h1>
            <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'><Icon size='big' name='twitter' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'><Icon size='big' name='instagram' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'><Icon size='big' name='facebook' /></Link>
            <Link style={{textDecoration: 'none'}} to='https://www.youtube.com/@navscout'><Icon size='big' name='youtube' /></Link>
        </Container>
    )
}

export default About;