import Util from "./Util"

class AppInfo {
    static PROFILE_WEB = 'web'
    static PROFILE_ANDROID = 'native.android'
    static PROFILE_ANDROID_GOOGLE = 'native.android.google'
    static PROFILE_IOS = 'native.ios'
    static PROFILE_MACINTOSH = 'native.macintosh'
    static PROFILE_WINDOWS = 'native.windows'

    static PLATFORM_USER_AGENT_PATTERNS = {
        'Android': [/android/i],
        'Linux': [/linux/i],
        'Macintosh': [/macintosh/i],
        'Windows': [/windows/i],
        'iOS' : [
            /iphone/i,
            /ipad/i,
            /ipod/i
        ]
    }

    static PLATFORM_PROFILE = {
        'Android' : this.PROFILE_ANDROID,
        'iOS' : this.PROFILE_IOS
    }

    static isProfile(profile) {
        return profile === this.PROFILE_ANDROID_GOOGLE
            || profile === this.PROFILE_ANDROID
            || profile === this.PROFILE_IOS
            || profile === this.PROFILE_MACINTOSH
            || profile === this.PROFILE_WINDOWS
            || profile === this.PROFILE_WEB
    }

    static getRelatedApps() {
        if (navigator.getInstalledRelatedApps) {
            return navigator.getInstalledRelatedApps()
        } else {
            return {then(f) {f([])}}
        }
    }

    /**
     * 
     * @returns true if payment can be managed outside of platform (ie App Store, Play Store)
     */
    static allowPaymentManagement() {
        const profile = this.getProfile()
        
        //Disallow payment in android apps, this must be done using Google Pay, etc.
        if (this.isAndroidApp()) {
            return false
        }

        return profile === this.PROFILE_WINDOWS
            || profile === this.PROFILE_MACINTOSH
            || profile === this.PROFILE_WEB
    }

    /**
     * 
     * @returns true if the app is running as an Android app. This may not work on ChromeOS.
     */
    static isAndroidApp() {
        return document.referrer.startsWith('android-app://')
    }

    /**
     * 
     * @returns current profile
     */
    static getProfile() {
        return Util.getCookie("app.profile") || this.PROFILE_WEB
    }

    static getUserAgent() {
        return window.navigator?.userAgent
    }

    static getUserAgentData() {
        return window.navigator?.userAgentData
    }

    static getDisplayMode() {
        if (document.referrer.startsWith('android-app://')) {
            return 'twa';
        }
        if (window.matchMedia('(display-mode: browser)').matches) {
            return 'browser';
        }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return 'standalone';
        }
        if (window.matchMedia('(display-mode: minimal-ui)').matches) {
            return 'minimal-ui';
        }
        if (window.matchMedia('(display-mode: fullscreen)').matches) {
            return 'fullscreen';
        }
        if (window.matchMedia('(display-mode: window-controls-overlay)').matches) {
            return 'window-controls-overlay';
        }
    
        return 'unknown';        
    }

    static setProfile(profile, force=false) {
        const currentProfile = Util.getCookie("app.profile")

        if (currentProfile && !force) {
            return
        }

        //If profile is not set, try to determine it
        if (!profile) {
            //if display mode is browser, use browser profile
            if (this.getDisplayMode() === 'browser') {
                profile = this.PROFILE_WEB
            } else {
                //Use platform profile or default to web
                const platform = this.getPlatform()
                profile = this.PLATFORM_PROFILE[platform] || this.PROFILE_WEB
            }
        }

        if (this.isProfile(profile)) {
            console.log(`app.profile=${profile}`)
            Util.setCookie("app.profile", profile, 31536000)
        }
        return this.getProfile()
    }

    static isMobile() {
        const userAgent = this.getUserAgent()
        const userAgentData = this.getUserAgentData()
        if (userAgentData) {
            return userAgentData.mobile
        }
        if (userAgent) {
            const patterns = [
                /iphone/i,
                /ipad/i,
                /android/i
            ]
            if (patterns.filter(p => p.test(userAgent)).length > 0) {
                return true
            }
        }
        return false
    }

    static getPlatform() {
        const userAgent = this.getUserAgent()
        const userAgentData = this.getUserAgentData()
        if (userAgentData && userAgentData.platform) {
            return userAgentData.platform
        }
        if (userAgent) {
            const platform = Object
                .entries(this.PLATFORM_USER_AGENT_PATTERNS)
                .filter(p => p[1].filter(pattern => pattern.test(userAgent)).length > 0)
                .map(platform => platform[0])[0]
            
            return platform || 'Unknown'
        }
    }
}

export default AppInfo;