import React, { useState } from 'react';
import { Button, Checkbox, Confirm, Container, Divider, Form, Input, Message, Radio, Select, Transition } from 'semantic-ui-react';
import DateTimeInput from './DateTimeInput';
import Hideable from './Hideable';
import LocationInput from './LocationInput';
import PersonSearch from './PersonSearch';
import Validate from './Validate';
import gql from 'graphql-tag';

const ADD_TRAVEL = gql`
mutation AddTravel($departure:Date,$origin:String,$dest:String,$speedLimit:Float) {
	addTravel(input:{departure:$departure, route:[{label:$origin},{label:$dest}],navigationOptions:{speedLimit:$speedLimit}}) {
    id
  }
}`
;

const UPDATE_TRAVEL = gql`
mutation UpdateTravel($id: String!, $departure:Date,$origin:String,$dest:String,$speedLimit:Float) {
	updateTravel(id: $id, input:{departure:$departure, route:[{label:$origin},{label:$dest}],navigationOptions:{speedLimit:$speedLimit}}) {
    id
  }
}`
;

const DELETE_TRAVEL = gql`
mutation DeleteTravel($id: String!) {
  deleteTravel(id:$id)
}
`;

const SET_ASSIGNMENT = gql`
mutation SetAssignment($travel:String!,$person:String!,$task:Task!) {
	setTravelAssignment(travel:$travel,person:$person,task:$task)
}`
;

const toMetric = (value, unit) => {
  if (unit === 'mph') {
    return value * 1.60934
  }

  return value
}

const toImperial = (value, unit) => {
  if (unit === 'kmph') {
    return value / 1.60934
  }

  return value
}

const parseDate = str => {
  if (str) {
    return new Date(str)
  }
  return null
}

const first = list => {
  if (list) {
    return list[0]
  }
  return null
}

const last = list => {
  if (list) {
    return list[list.length - 1]
  }
  return null
}

const TravelForm = props => {
    let [origin, setOrigin] = useState(props.origin || first(props.travel?.route)?.label || '');
    let [dest, setDest] = useState(props.destination || last(props.travel?.route)?.label || '');
    let [cancelOpen, setCancelOpen] = useState(false);
    let [departTime, setDepartTime] = useState(parseDate(props.travel?.departure) || new Date());
    let [leaveNow, setLeaveNow] = useState(!props.travel?.departure);
    let [driver, setDriver] = useState(null);
    let [showAdvanced, setShowAdvanced] = useState(props.travel?.speedLimit ? true : false);
    let [limitSpeed, setLimitSpeed] = useState(props.travel?.speedLimit);
    let [speedLimit, setSpeedLimit] = useState(Math.round(toImperial(props.travel?.speedLimit, 'kmph')) || 65);
    let [speedLimitUnit, setSpeedLimitUnit] = useState('mph');
    return <div>
      <Form className='attached fluid segment' onSubmit={e => {
          e.preventDefault();
          saveTravel(
            props.client,
            props.travel?.id,
            origin,
            dest,
            leaveNow ? new Date() : departTime,
            driver,
            setSpeedLimit ? toMetric(speedLimit, speedLimitUnit) : null,
            props.onSave
          );
        }} >
        <Form.Group widths='equal'>
          <LocationInput id='origin' 
              label='Starting Location'
              // placeholder='Los Angeles, CA'
              location={origin} 
              onChange={l => setOrigin(l?.label)} 
              client={props.client}
              />
          <LocationInput id='dest' 
              label='Destination'
              // placeholder='San Diego, CA'
              location={dest} 
              onChange={l => setDest(l?.label)} 
              client={props.client}
              />
        </Form.Group>
        <Hideable hidden={props.travel?.id}>
          <Radio toggle
            label='Leave Now'
            checked={leaveNow}
            onChange={e => setLeaveNow(!leaveNow)}
            />
        </Hideable>
        <Transition.Group animation='slide down' duration={100}>
          {!leaveNow && (
            <Container>
              <DateTimeInput id='departTime' 
                  label='Departure Time'
                  placeholder='Time'
                  value={departTime}
                  onChange={setDepartTime}
                  />
            </Container>
          )}
        </Transition.Group>
        <Hideable hidden={props.basic}>
          <Divider />
          <Radio toggle
              label='Advanced Options'
              checked={showAdvanced}
              onChange={e => setShowAdvanced(!showAdvanced)}
              />
          <Transition.Group animation='slide down' duration={100}>
            {showAdvanced && (
                  <Form.Group widths='equal' style={{'marginLeft':'0em'}}>
                    <Divider hidden />
                    <Hideable hidden={props.travel?.id}>
                      <PersonSearch 
                          id='driver'
                          placeholder='Driver'
                          onChange={e => setDriver(e.currentTarget.value.id)}
                          client={props.client}
                          />
                    </Hideable>
                    <Divider hidden />
                    <Checkbox toggle label='Limit Speed' checked={limitSpeed} onChange={() => setLimitSpeed(!limitSpeed)} />
                    <Input type='number' value={speedLimit} disabled={!limitSpeed} onChange={(e,d) => setSpeedLimit(d.value) } />
                    <Select 
                      disabled={!limitSpeed}
                      options={[
                        {key: 'mph', value: 'mph', text: 'Miles Per Hour'},
                        {key: 'kmph', value: 'kmph', text: 'Kilometers Per Hour'},
                      ]}
                      value={speedLimitUnit}
                      onChange={(e, d) => setSpeedLimitUnit(d.value)}
                      defaultValue={speedLimitUnit}
                      />
                  </Form.Group>
            )}
          </Transition.Group>
        </Hideable>
        
        <Container textAlign='center'>
          <Button              
              style={{margin:'0 auto 0 auto'}}
              disabled={props.loading || !props.account || !(Validate.notBlank(origin, dest) && Validate.notSame(origin, dest)) }
              >{props.id ? 'Update' : 'Go'}</Button>
          &nbsp;
          <Hideable hidden={!props.travel?.id}>
          <Confirm 
            open={cancelOpen}
            onCancel={() => setCancelOpen(false)}
            onConfirm={() => {deleteTravel(props.client, props.travel?.id, props.onDelete);setCancelOpen(false)}}
            trigger={<Button onClick={(e) => {
              e.preventDefault();
              setCancelOpen(true);
            }}>Delete</Button>} 
            />
            </Hideable>
            <Divider hidden />
        </Container>
</Form>
<Hideable hidden={props.account}>
  <Message warning attached='bottom'>
    You will need to <a href='/register'>register</a> or <a href='/sign-in'>sign-in</a> to get a forecast for your route.
  </Message>
</Hideable>
</div>;
}

const saveTravel = (client, id, origin, dest, departure, driver, speedLimit, onSave) => {
  client.mutate({
    mutation: id ? UPDATE_TRAVEL : ADD_TRAVEL,
    variables: {id, origin, dest, departure, speedLimit},
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (data.data.addTravel === null) {
      console.log('Add travel failed');
    } else {
      console.log('Updated travel')
      if (driver) {
        setAssignment(client, data.data.addTravel.id, driver, 'DRIVER', () => {});
      } else {
        //close
      }
      onSave?.(data.data.addTravel)
    }
  });
}

const setAssignment = (client, travel, person, task, then) => {
  client.mutate({
    mutation: SET_ASSIGNMENT,
    variables: {travel, person, task},
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (data.data.setTravelAssignment === null) {
      console.log('Set assignment failed');
    } else {
      then();
    }
  });
}

const deleteTravel = (client, id, onDelete) => {
  console.log('Delete travel: ' + id);
  
  client.mutate({
    mutation: DELETE_TRAVEL,
    variables: {id},
    refetchQueries: ['TravelInformation']
  }).then(data => {
    if (!data.data.deleteTravel) {
      console.log('Delete travel failed');
    }
    console.log('Deleted travel')
    onDelete?.(id)
  });
}

export default TravelForm;