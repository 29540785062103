import React, { useState } from 'react';
import { Button, Card, CardGroup, Container, Divider, Form, FormField, FormGroup, Grid, GridColumn, GridRow, Image, Input, Message } from 'semantic-ui-react';
import Hideable from './Hideable';
import { Link, useNavigate } from 'react-router-dom';
import TravelForm from './TravelForm';

const Home = props => {
  document.title = `navscout`;
  const [dismissUpdateNotification, setDismissUpdateNotification] = useState(false);
  const navigate = useNavigate();

  return (
    <Container>
      <h1>navscout</h1>
      <p style={{fontSize: '1.5em'}}>
      Get a weather forecast for your drive.
      </p>
      <TravelForm 
            basic={true} 
            client={props.client} 
            account={props.account} 
            onSave={travel => {navigate(`/drive/${travel.id}`)}}
            />
      <Divider />
      <p>   
            <div style={{width: '553', height: '700'}}>
                  <Image 
                        src='/image/home/overview.png' 
                        alt='A weather forecast for the users drive and a depiction of the conditions on a map'
                        style={{margin: 'auto'}} 
                        />
            </div>
      </p>
      <Hideable hidden={!window.notifyNewUpdate || dismissUpdateNotification}>
            <Message onDismiss={() => {
                  window.notifyNewUpdate = false;
                  setDismissUpdateNotification(true)
            }}>
                  A new update is available, close the application to update it.
            </Message>
      </Hideable>
      <Divider />
      <p>
            <h2>Detect hazards on your route</h2>
            <div style={{width: '451', height: '130'}}>
                  <Image 
                        src='/image/home/alert.png' 
                        alt='A hazard detected on the users scheduled drive'
                        loading='lazy' 
                        style={{boxShadow: '0px 0px 10px #ccc', borderRadius: '0.5em', marginTop: '1em'}} 
                        />
            </div>
            <br/>
            navscout can detect hazards on your route such as high wind speeds, crosswinds, snow accumulation, glare, and more. It also 
            alerts you to icy roads, heavy rain, and dense fog, ensuring you're prepared for adverse weather conditions.
      </p>
      <Divider />
      <p>
            <h2>Tell at a glance how the weather will change</h2>
            <div style={{width: '553', height: '118'}}>
                  <Image 
                        src='/image/home/forecast.png' 
                        alt='A weather forecast for the users drive'
                        loading='lazy' 
                        style={{boxShadow: '0px 0px 10px #ccc', borderRadius: '0.5em', marginTop: '1em'}} 
                        />
            </div>
            <br/>
            The navscout weather forecast feature provides you with an immediate understanding of how weather conditions will evolve, 
            allowing you to plan your trip accordingly. It displays at-a-glance information on expected changes in temperature, precipitation, 
            and wind, ensuring you can hit the road without unnecessary delays.
      </p>
      <Divider />
      <p>
            <h2>View weather changes on the travel map</h2>
            <div style={{width: '553', height: '629'}}>
                  <Image 
                        src='/image/home/map.png' 
                        alt='A map showing conditions on the users drive'
                        loading='lazy' 
                        style={{boxShadow: '0px 0px 10px #ccc', borderRadius: '0.5em', marginTop: '1em'}} 
                        />
            </div>
            <br/>
            "Use the interactive travel map to pinpoint exactly where weather hazards are expected to occur along your route. This tool offers 
            a detailed view of how conditions like high winds, heavy rain, or snow will transition across different segments of your journey. 
      </p>
      <Divider />
      <Grid columns={3} divided>
            <GridColumn>
                  <GridRow>About</GridRow>
                  <GridRow>
                        <Link to='/about/privacy'>Privacy Policy</Link><br/>
                        <Link to='/about/terms-of-service'>Terms of Service</Link><br/>
                        <a href='mailto:contact@navscout.com'>Contact</a><br />
                  </GridRow>
            </GridColumn>
            <GridColumn>
                  <GridRow>App</GridRow>
                  <GridRow>
                        <a href='https://store.app/navscout'>store.app</a>
                  </GridRow>
            </GridColumn>
            <GridColumn>
                  <GridRow>Social</GridRow>
                  <GridRow>
                        <Link style={{textDecoration: 'none'}} to='https://www.twitter.com/navscout/'>X</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.instagram.com/trynavscout/'>Instagram</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.facebook.com/navscout/'>Facebook</Link><br/>
                        <Link style={{textDecoration: 'none'}} to='https://www.youtube.com/@navscout'>Youtube</Link><br/>
                  </GridRow>
            </GridColumn>
      </Grid>
    </Container>
  )
}


export default Home;