class Util {
    static parseJson(s) {
        if (s === null || s === undefined) {
            return null
        }

        return JSON.parse(s)
    }


    static equals(a, b) {
        let isEqual = Object.is(a, b);
        
        if (!isEqual) {
            if (Array.isArray(a) && Array.isArray(b)) {
        
            isEqual = (a.length === b.length) && a.every(
                (item, idx) => Util.equals(item, b[idx])
            );
            } else if (
            a && b
            && (typeof a === 'object')
            && (typeof b === 'object')
            ) {
            const aKeys = Object.keys(a);
            const bKeys = Object.keys(b);
        
            isEqual = (aKeys.length === bKeys.length) && aKeys.every(
                (key, idx) => Util.equals(a[key], b[key])
            );
            }
        }
        return isEqual;
    }

    /**
     * 
     * @param {*} name Name of cookie
     * @param {*} value Value of cookie
     * @param {*} maxAge Max age of cookie in seconds
     */
    static setCookie(name, value, maxAge) {
        document.cookie = `${name}=${value}; max-age=${maxAge}`;
    }

    static getCookie(name) {
        return document.cookie
            .split("; ")
            .find((row) => row.startsWith(`${name}=`))
            ?.split("=")[1];
    }

    static value(n) {
        if (n == null) {
            return null;
        }
        var e = document.getElementById(n);
        if (e == null) {
            return null;
        }
        return e.value;
    }

    static subjectLink(type, id) {
        if (type === 'PLACE') {
            return `/place/${id}`
          } else if (type === 'RECORD') {
            return `/report/${id}`
          } else if (type === 'TRAVEL') {
            return `/drive/${id}`
          }
    }

    static handler(f, args) {
        return (e) => {
            if (e.preventDefault) e.preventDefault();
            if (args) {
                f(e, ...args);
            } else {
                f(e);
            }
        }
    }

    static maybe(v, c) {
        return v ? c : null
    }

    static dollars(p) {
        return '$' + (p / 100).toFixed(2)
    }

    static traverse(o, p) {
        if (!o) return o;
        if (!p) return o;
        var keys = p.split(".")
        for (var i = 0; i < keys.length; i++) {
            o = o[keys[i]];
            if (!o) return o;
        }
        return o;
    }

    static linkWithParam(link, param) {
        var value = new URLSearchParams(window.location.search).get(param);
        return param ? link + '?' + param + '=' + value : link;
    }

    static capitalize(str) {
        return str ? 
            str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
            : "";
    }

    static hasSession() {
        return document.cookie?.indexOf("NS_SESSION") !== -1;
    }

    static hostname(uri) {
        if (!uri) { return null }
        try {
            return new URL(uri).hostname
        } catch (err) {
            return null
        }
    }

    //l1 contains all items in l2
    static containsAll(l1, l2) {
        return l2?.map(a => l1.includes(a)).reduce((a, b) => a && b)
    }

    static print(v) {
        if (v === null) {
            return '';
        }
        if (typeof(v) === 'undefined') {
            return '';
        }
        return '' + v;
    }

    static isDefined(v) {
        return v !== null && v !== undefined
    }

    static isBlank(v) {
        return v === null || v === undefined || v === ""
    }
}

export default Util;